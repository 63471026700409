/* breadcrumbs */
.breadcrumbs {
	font-style: italic;
	padding-top: 12px;
	font-size: @md-font;
	overflow: auto;

	.separator {
		font-weight: bold;
	}

	.active {
		color: #e40000;
		font-weight: bold;
	}
}
