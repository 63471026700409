.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1040;

	.popup-overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.8);
		z-index: 1040;
	}

    .popup-wapper {
		background: #FFF;
		min-height: 100px;
		max-width: 400px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1041;
		border: 1px solid #909090;
		box-shadow: 1px 1px 5px 0px #000;
		border-radius: 5px;
		max-height: 500px;
		min-width: 200px;

		.popup-title {
			padding: 15px;
			box-shadow: 0px 1px 3px #CCC;
			border-radius: 5px 5px 0px 0px;
			position: relative;
			cursor: default;

			* {
				margin: 0;
				padding: 0;
			}

			.close {
				margin-top: -23px;
			}
		}

		.popup-content {
			padding: 15px;
			font-size: @md-font;
		}
    }
}