/* navbar */
#useful-links {
	ul {
		white-space: nowrap;
		z-index: 99999;
	}

	a{
		border: none;
	}

	a.btn-green:hover{
		background-color: #4eb623;
	}
}

.navbar{
	border-radius: 4px;

	&.navbar-inverse {
		min-height: 40px;
		z-index: 1029;

		.navbar-nav {
			& > .active > a{
				border-bottom: 2px solid #e40000;
			}

			& > li {

				&::after {
					display: block;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					width: 1px;
					background: #444;
				}

				> a {
					font-family: 'oswald', 'lato';
					text-transform: uppercase;
					letter-spacing: 0.15px;
					font-weight: 500;
					font-size: @md-font;
					color: #fff;
					position: relative;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
		}
	}
}

.desktop .navbar.navbar-inverse .navbar-nav > .active > a {
	margin-bottom: -2px;
}

.cart-holder {
	.empty {
		cursor: default !important;
	}

	> li > a {
		background: #ed1e24 url('../img/icon-cart.png') no-repeat 10px !important;
		padding-left: 40px;
	}

	.cart-holder-items {
		padding: 0;
		margin: 0;
		z-index: 1;
		width: 400px;
		left: -230px;
		top: 102%;
		border: 1px solid #CCC;
		position: absolute;
		max-height: 350px;
		overflow: auto;
		box-shadow: 1px 1px 3px #CCC;

		li {
			background: #FFF;
			border-bottom: 1px solid #CCC;
			font-size: @lg-font !important;
			overflow: auto;
			list-style: none;

			.cart-item-data a {
				display: block;
				overflow: auto;
			}

			.action {
				margin-top: 15px;
			}

			.image {
				float: left;
				padding: 0 10px 0 0;
				border-right: 1px solid #CCC;
				margin-right: 10px;

				img {
					max-height: 64px;
				}
			}

			.title {
				display: block;
				margin-top: 7px;
				line-height: 12px;
			}

			.value {
				display: block;
				font-size: @xlg-font;
				font-weight: bold;
				margin-top: 3px;
			}
		}
	}
}


.desktop .navbar > .container-fluid {
	padding: 0;

	.navbar-header {
		display: none;
		visibility: hidden;
	}

	#navbar {
		padding-left: 0;
	}
}

.tablet {
	#navbar {
		margin: 0 -15px;
		.navbar-nav {
			float: none;

			li {
				float: none;
			}
		}
	}
}

// .tablet-menu {
// 	.navbar-collapse {
// 		.navbar-nav:first-child {
// 			width: 84.8%;
// 			margin-left: -30px;
// 			li{
// 				display: block;
// 				float: none;
// 			}
// 		}
// 	}

// 	.navbar-header {
// 		display: none;
// 		visibility: hidden;
// 	}

// 	.cart-holder {
// 		margin-right: -30px;
// 		border-top-right-radius: 3px;

// 		> li > a {
// 			white-space: nowrap;
// 			padding: 17px 15px 15px 40px;
// 		}
// 	}
// }