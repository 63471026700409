/* sidebar */
.desktop {
	.list-group-item {
		min-width: 25%;
	}
}

#sidebar-wrapper {
	background-color: #fff;

	> .panel > .panel-body {
		position: relative;
		overflow: hidden;
	}

	ul.list-group {
		&.panel-slide {
			position: absolute;
			left: 100%;
			top: 0;
			z-index: 100;
			background: #fff;
			height: 100%;
			width: 100%;
			transition: all 1s ease;
			overflow: auto;

			&.slide-in {
				left: 0;
			}
		}
	}

	&.closed {
		left: -100%;
		position: fixed;
		width: 100%;
		min-height: 100%;
		top: 0;
		z-index: 1030;
	}

	&.open{
		left: 0;
	}

	&> .panel > .panel-body{
		padding: 0;
	}

	#attributes-filter {
		 .list-group-item {
			float: left;
			padding: 1% 3%;
			border-radius: 0;
			border: 0;
			text-transform: uppercase;
			label {
				font-size: (@sm-font + 1px);
			}
		}

		.panel:not(:first-of-type) .list-group-item {
			float: none;
			display: block;
		}

		.panel{
			clear: both;
			border-radius: 0;
			border-bottom: 1px solid #DDD;
			padding-bottom: 5px;

			&:nth-child(5) {
				border-bottom: none;
			}

			.panel-heading{
				border-color: #ddd;
				border-radius: 0;
			}
		}

		.panel-default > .panel-heading{
			font-style: italic;
			padding: 10px;
		}	

		.panel-default > .panel-heading h6 {
			font-weight: 600;
			color: #ed1f24;
			font-style: italic;
			margin-bottom: 2px;
			margin-top: 2px;
		}

		.panel .panel-heading {
			background: none;
			border: none;
		}

		.checkbox {
			margin-top: 2px;
			margin-bottom: 2px;

			label{
				font-style: italic;
				padding-left: 0px;
			}

			span.checkbox {
				height: 14px;
				width: 14px;
				display: inline-block;
				vertical-align: middle;
				border: 1px solid #ddd;
				position: relative;
				margin-right: 5px;

				&.active {
					background: url('../img/icon-check.png') no-repeat;
					background-size: 100%;
				}

			}
		}
	}

	&.sidebar-mobile {
		background-color: #222;
		border-color: #080808;
		padding-top: 40px;

		.input.submit {
			display: none;
			visibility: hidden;
		}

		.panel {
			background: none;
			border: none;
			margin-bottom: 1px;
			border-bottom: 1px solid #000;
			-o-box-shadow: 0px 1px 1px rgba(255,255,255,0.1);
			-ms-box-shadow: 0px 1px 1px rgba(255,255,255,0.1);
			-moz-box-shadow: 0px 1px 1px rgba(255,255,255,0.1);
			-webkit-box-shadow: 0px 1px 1px rgba(255,255,255,0.1);
			box-shadow: 0px 1px 1px rgba(255,255,255,0.1);

			.panel-heading {
				background-color: #252525;
				position: relative;

				&::after {
					content: "\e080";
					position: absolute;
					display: inline-block;
					font-family: 'Glyphicons Halflings';
					font-style: normal;
					font-weight: 400;
					-webkit-font-smoothing: antialiased;
					right: 15px;
					top: 13px;
					color: #fff;
				}
			}

			.list-group {
				display: none;
				visibility: hidden;
				top: 0;
				background: #222;
				padding: 0px 15px 20px 15px;
				z-index: 1;
				overflow: auto;

				&.visible {
					visibility: visible;
				}

				.list-group-item {
					background-color: #303030;
					border: 1px solid transparent;
					color: #ffffff;
					transition: 500ms all ease;
					cursor: pointer;
					text-transform: uppercase;

					span.checkbox {
						display: none !important;
					}

					&.checked {
						border: 1px solid #303030;
						color: #303030;
						background-color: #fff;
					}
				}
			}
		}

		> .panel {
			margin: 0 -15px;
			border-bottom: none;
			-o-box-shadow: none;
			-ms-box-shadow: none;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;

			> .panel-heading {
				display: none;
				visibility: hidden;
			}
		}

		.list-group-item {
			margin-right: 5px;
			padding: 0;
			margin-bottom: 5px;

			.checkbox label {
				padding: 0;
			}

			input {
				display: none;
			}
		}
	}

	.sidebar-title {
		background-color: #ed1e24;
		margin: 0 -15px 15px -15px;
		padding: 10px 15px;
		color: #fff;
		font-size: 16px;
		box-sizing: border-box;

		.btn-sidebar-close {
			padding: 0;
			border: none;
			background: none;
			font-size: 16px;
			margin-top: -3px;
		}
	}
}

.desktop {
	#sidebar-wrapper {
		.panel, .panel-heading {
			border: none;
		}

		.panel-heading h3 {
			color: #222;
		}

		.panel-heading h6 .selected {
			display: none;
		}

		.panel-body {
			border: 1px solid #ddd;

			.panel {
				margin: 0;
				box-shadow: none;
			}

			ul.list-group {
				overflow: auto;
				padding: 0 5px 0px;
				display: block !important;
			}
		}

		.category-tree {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
			}

			ul.list-group > li:not(:last-child) {
				border-bottom: 1px solid #CCC;
			}

			ul.list-group > li {
				padding-bottom: 5px;
			}

			ul.list-group > li > a {
				font-weight: 600;
				color: #ED1F24;
				font-style: italic;
				margin-bottom: 2px;
				margin-top: 2px;
				padding: 5px 0 0 0;
				font-size: 13px;
				display: block;
			}

			ul.list-group > li > ul {
				padding-left: 10px;
			}
		}
	}
}

.mobile {
	#sidebar-wrapper #attributes-filter .panel {
		border-bottom: 1px solid #000 !important;
	}
	#sidebar-wrapper #attributes-filter .panel:not(:first-of-type) .list-group-item {
		display: inline-block;
  		width: 48%;
	}
}