/*buttons*/
.btn {
	padding: 6px;
	transition: 0.2s all ease;

	&:focus, &:active:focus {
		outline: none;
	}

	&:active {
		text-shadow: 0px 0px 2px #303030;
	}
}

.btn-green, .btn-red{
	white-space: nowrap;
	font: bold @md-font 'Oswald',lato;
	color: #fff !important;
	text-transform: uppercase;
	border-radius: 4px;
}

.btn-green{
	-webkit-box-shadow: 0 4px 0 0 #45961E;
	-moz-box-shadow: 0px 4px 0px 0px rgba(255, 0, 0, 0.75);
	box-shadow: 0px 4px 0px 0px #45961e;
	background-color: #4eb623;
}

.btn-red{
	background-color: #E40000;
	-webkit-box-shadow: 0px 4px 0 0 #c51a1e;
	-moz-box-shadow: 0px 4px 0px 0px rgba(255, 0, 0, 0.75);
	box-shadow: 0px 4px 0px 0px #c51a1e;
	text-align: center;
}

.btn-options{
	border: none;
	background: #272727;
	color: #fff !important;
	font-weight: 600;
	letter-spacing: 1px;
	text-align: left;
	margin-bottom: 15px;

	&:hover{
		background: #272727;
	}
}

.btn-back {
	border-radius: 0;
	box-shadow: 3px 0px 3px 1px #000;
	background-color: #ED1F24;
	color: #FFF;
	font-weight: bold;
	text-shadow: 1px 1px 0px #000;
	border: 1px solid #E23B3F;
	border-right: 0;
	border-left: 0;
	cursor: pointer;

	&.btn-disabled {
		background-color: rgba(0, 0, 0, 0.2);
		border-color: #222;
	}

	&:visited, &:hover, &:focus {
		color: #fff;
	}
}

.btn-facebook {
	background-image: url('../img/icon-facebook-login.png');
	background-size: 35px;
	background-color: rgb(59, 87, 157);
	background-repeat: no-repeat;
	border: none;
	-webkit-box-shadow: 0 4px 0 0 #0F2969;
	-moz-box-shadow: 0px 4px 0px 0px rgba(255, 0, 0, 0.75);
	box-shadow: 0px 4px 0px 0px #223E82;
	color: #fff;
	font: bold 13px 'Oswald', lato;
	text-transform: uppercase;
	border-radius: 4px;
	line-height: 22px;
	&::before {
		content: ' ';
		width: 1px;
		border-right: 1px solid rgba(0, 0, 0, 0.3);
		box-shadow: 1px 0px 0px rgba(255, 255, 255, 0.1);
		height: 100%;
		position: absolute;
		top: 0;
		left: 55px;
	}
	&:visited, &:hover, &:focus {
		color: #fff;
	}
}

.btn-top {
	cursor: pointer;
	color: #ffffff !important;
	display: block;
	line-height: 30px;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: right;
	font-family: 'Oswald', lato;
}

.btn-loader {

	background-color: #222;
	color: #FFF;
	font-weight: bold;
	border: 1px solid #222;
	transition: 0.5s all ease-in-out;

	&:focus {
		color: #fff;
		border-color: #9B0606;
	}

	&.loading {
		background-color: #4EB623;
		border-color: #358912;
	}

	&.no-results {
		background-color: #D00;
		border-color: #9B0606;
	}
}

.logout-container .btn-green {
	margin-top: 45px;
}

.btn-small {
	padding: 4px 10px !important;
}