.mobile .offer-info {
	.more-views {
		visibility: hidden;
		position: fixed;
		top: 0px;
		left: -100%;
		margin: 0;
		padding: 40px 0 0 0;
		width: 100%;
		height: 100%;
		z-index: 98;
		background: #f0f0f0;
		transition: 0.5s all ease-in-out;
		div {
			width:100%;
			position: relative;
			margin-right: 0 !important;
			// border-bottom: 1px solid #CCC;
			margin-bottom: 15px;

			img {
				width: 100%;
				max-width: 100%;
			}
		}

		.actions {
			border-top: 1px solid #CCC;
			padding-top: 15px;
			text-align: center;

			.bullet {
				text-align: center;
				margin: 12px 5px 0px 5px;
			}
			.left-action, .right-action {
				border: none;
				width: 32px;
				height: 32px;
				position: absolute;
				right: 25px;
			}

			.left-action {
				left: 25px;
			}

			.bullet {
				display: inline-block;
				width: 8px;
				height: 8px;
				background: #909090;
				border-radius: 100%;
				margin: 12px 5px 0px 5px;

				&.active {
					background: #dd0000;
				}
			}
		}
	}
}

.offer {

	padding-right: 10px;
	padding-left: 10px;
	.row {
		margin-left: -10px;
		margin-right: -10px;
		> div {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	margin-bottom: 15px;

	.offer-image {
		position: relative;

		a {
			display: block;
			text-align: center;
		}
	}

	.offer-name h3 {
		min-height: 60px;
		line-height: 14px;
		font-size: @md-font;
	}

	.offer-bid-info {
		margin-bottom: 5px;

		.offer-price, .offer-bid {
			font-style: italic;
			font-size: @sm-font;

			.offer-price-current, .text-value {
				font-size: @md-font;
				display: block;
			}
		}

		.offer-bid {
			text-align: right;
			padding-left: 0;
		}
	}

	.offer-progress-info {
		min-height: 34px;
		margin-bottom: 6px;

		.title {
			min-height: auto;
		}

		.value {
			font-size: @sm-font+1px;
			line-height: 18px;
		}

		p {
			margin: 0;
			padding: 0px;
			&.text-danger{
				color: #ed1e24;
				font-weight: 600;
				font-style: italic;
				font-size: @sm-font;
				line-height: 16px;
				margin-bottom: 1px;
			}
		}
	}

	.offer-state {
		border-radius: 4px;
		background: #e6e6e6;
		color: #525252;
		text-align: center;
		margin: 0px 0 0px 0;
		padding: 10px 0;

		p {
			padding: 0;
			margin: 0;
			font-weight: bold;
			font-style: italic;
		}

		&.offer-ended {
			background: #ed1e24;
			color: #fff;
			padding: 7px 9px 8px 9px;
		}

		&.offer-won {
			background: #00a9a4;
			color: #fff;
			padding: 9px 0;
		}

		&.offer-bid {
			background: #ffea01;
			color: #000;
			text-align: center;
		}

		&.offer-date {
			.inline-text {
				margin: 0 -15px;
			}

			.inline-text, .countdown {
				font-size: @sm-font;
			}

			.countdown.hasCountdown {
				font-weight: bold;
				color: #000;
			}
		}
	}

	&.offer-state-future {
		.offer-date {
			white-space: nowrap;
			margin-top: 18px;
			padding: 4px 0;
			padding-bottom: 3px;

			.inline-text, .countdown {
				font-size: @xsm-font;
			}
		}

		.offer-bid-buy {
			.btn-red {
				font-size: @sm-font;
			}
		}

		.offer-progress-info {
			min-height: inherit;
		}

		.offer-price-from {
			white-space: nowrap;

			.value {
				text-decoration: line-through;
				font-size: @xsm-font;
			}
		}

		.offer-price-to {
			white-space: nowrap;
			padding-left: 0px;
		}
	}

	> .auction-overcome {
		border: 1px solid #F68F91;
		padding: 5px;

		.offer-name h3 {
			min-height: 59px;
		}

		.offer-bid-info, .offer-progress-info {
			margin-bottom: 0;
		}

		.icon.icon-overcome {
			width: 93.5%;
			background: rgba(255, 255, 255, 0.9) url('../img/icon-overcome.png') no-repeat center;
			position: absolute;
			bottom: 0;
			padding: 15px;
			left: 5px;
		}
	}
}

/**
 * Offer Information
 */
.offer-info {

	.offer-bid-form form > .form-group:nth-of-type(1) {
		padding-right: 0;

		input {
			line-height: 35px;
			height: 39px;
		}
	}

	.more-views {
		overflow: hidden;

		&.active {
			left: 0;
		}

		.slider-wrapper {
			overflow: hidden;
			position: relative;

			div {
				float:left;
				width: 14.999833333%;
				padding: 2%;
				border: 1px solid #f0f0f0;
				position: relative;
				margin-bottom: 15px;
				margin-right: 2%;

				&:last-child {
					margin-right: 0;
				}

				img {
					max-width: 100%;
				}
			}
		}

		&.more-views-3 {
			.slider-wrapper > div {
				width: 30%;
			}
		}

		.bullet {
			margin: 12px 5px 0px 5px;
		}

	}

	.offer-info-image {
		.zoomWrapper {
			position: relative;
		}

		.text-info {
			font-size: @sm-font;
			color: #909090;
			margin-top: 10px;
			margin-bottom: 0;
		}
	}

	.bid-info, .bid-date, .bid-offer, .bid-size {
		.value {
			text-align: right;
			font-size: @sm-font;
		}
	}

	.bid-size .title {
		white-space: nowrap;
	}

	.bid-info {
		.title {
			padding-top: 9px;
		}

		.value {
			font-size: @lg-font;
		}
	}

	.bid-offer {
		.text-danger {
			&:extend(.offer .offer-progress-info p.text-danger);
		}
	}

	.offer-state:extend(.offer .offer-state all) {
		margin-bottom: 10px;
	}

	.offer-buy-now {
		font-size: @sm-font;

		.text-value {
			font-size: @xlg-font;
			line-height: 35px;
			margin-top: 10px;
			padding-left: 0;
		}

		form {
			min-height: 40px;
			margin-top: 10px;
		}
	}

	.offer-info-discount {
		background: url('../img/icon-discount.png') no-repeat left top;
		line-height: 42px;
		display: block;
		width: 48px;
		text-align: right;
		padding-right: 3px;
		position: absolute;
		right: -5px;
		top: 10px;
		z-index: 5;
		.text-value {
			font-size: @sm-font;
			font-weight: bold;
			color: #fff !important;
		}
	}

	.offer-bid-check-shipping a {
		display: block;
		color: #E40000;
		cursor: pointer;
	}

}

.desktop .offer-info {
	.more-views {
		padding-left: 15px;
	}
}

.offer-info-description {
	.tablesorter {
		width: 100%;

		thead tr {
			background-color: #F2F2F2;
			height: 35px;
		}

		thead tr, tbody tr {
			th, td{
				padding: 0 10px;
			}
		}

		tbody tr {
			td .user, td .address{
				color: #777;
				font-weight: normal;
				display: inline;
				margin-right: 15px;
				font-size: 12px;
			}

			td .bid-date {
				font-size: 10px;
				color: #777;
				margin-bottom: 7px;
			}

			td:first-child{
				border: 1px solid #ccc;
				border-right: none;	
			}

			td:last-child{
				border: 1px solid #ccc;
				border-left: none;
				font-weight: bold;
			}
		} 
	}

	.shipping-address h6 {
		margin-top: 0;
		margin-bottom: 5px;
	}
}

#sidebar-right-related-offers{
	background-color: #e9e9e9;
	overflow: auto;
	margin: 0px 0px 0px -15px;
	
	h4{
		text-align: center;
		color: #ed1e24;
		font-weight: 600;
	}

	.offer-image {
		text-align: center;
		img {
			border: 1px solid #ccc;
		}
	}

	.offer-price {
		font-size: @xsm-font;
	}

	.offer-state-date .offer-date{
		border: 1px solid #ccc;
		border-radius: 0;
	}
}

.mobile #sidebar-right-related-offers {
	margin: 0px -15px 0px -15px;
}

.zoomContainer {
	z-index: 1030;

	.zoomWindowContainer .zoomWindow {
		box-shadow: 8px 0px 15px 0px rgba(0,0,0,0.4);
		border: 1px solid #ccc;
	}
}
