.g-recaptcha {
	margin-bottom: 15px;
	float: right;
}

.mobile .g-recaptcha {
	margin: 10px auto;
	width: 302px;
}

.accept-terms {
	float: left;
	width: 39%;
	background: #F9F9F9;
	padding: 7px 10px;
	min-height: 77px;
	box-sizing: border-box;
	border: 1px solid #D3D3D3;
	border-radius: 3px;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.08);
	-webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.08);

	> .checkbox {
	  	margin-top: 15px;
	}

	.pcs-fauxcheckbox {
		padding-left: 35px;
		line-height: 14px;
		font-size: 13px;

		input {
			display: none;
			visibility: hidden;
		}
	}

	span.checkbox {
		position: absolute;
		margin: 0;
		width: 28px;
		height: 30px;
		display: block;
		border: 2px solid #C1C1C1;
		background: #FFF;
		border-radius: 2px;
		margin-left: -35px;
	}
}

@media (max-width: 360px) {
	.accept-terms {
		float: none;
		width: 304px;
		margin: 0 auto;
	}
}

label a {
	font-weight: bold;

	&, &:visited {
		color: #ED1E24 !important;
	}

	&:focus, &:active, &:hover {
		color: #DD0000 !important;
	}
}

form .alert-warning p {
	font-size: @md-font;
}