/* useful stuff */
html{
	font-size: @md-font;
	position: relative;

	&, body {
		/* hack for bottom footer */
		min-height: 100%;
	}

	&> object { //fix for some unwanted plugins
		display: none;
		visibility: hidden;
	}
}

a, a:visited {
	color: #303030;
}

a:hover, a:active, a:focus {
	color: #909090;
	text-decoration: none;
}

body {
	font-family: 'lato', 'arial';
	padding-bottom: 140px;
	min-width: 345px;
	font-size: (@sm-font+1);

	&.desktop, &.tablet {
		padding-bottom: 320px;
	}

	> .container-fluid {
		min-height: 100%;
		height: auto;
	}
}

h1, h3, h4, h5, h6, .title {
	font-style: italic;

	span.selected {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 70%;
		display: inline-block;
		font-weight: normal;
		font-size: @sm-font;
		color: #909090;
		line-height: inherit;
		vertical-align: bottom;
		text-align: right;
		float: right;
		margin-right: 20px;
	}
}

.h1, .h2, .h3, h1, h2, h3{
	margin-top: 10px;
	margin-bottom: 5px;
}

h1 {
	letter-spacing: -2px;
	font-weight: 300;

	i {
		color: #ED1E24;
	}

	.badge {
		letter-spacing: 0px;
		top: -7px;
		position: relative;
		background-color: #4EB623;
	}
}

h2 {
	font-size: @xlg-font;
	color: #ed1e24;
	font-weight: bold;

	a {
		font-weight: normal;
		font-style: italic;
		margin-left: 15px;
		font-size: 70%;
		&:hover {
			color: #303030;
		}
	}
}

h3 {
	color: #555555;
	font-size: @xlg-font;
}

h4{
	font-size: @lg-font;
}

h6 {
	font-weight: bold;
	font-size: @md-font;
}

hr {
	margin: 10px 0;
}

p, label{
	font-size: @md-font;
}

address {
	font-style: italic;
	margin-bottom: 15px;
}

.alert a, .alert a:active, .alert a:focus {
	color: inherit;
}

.alert a:hover {
	text-decoration: underline;
}

.pd-r-0 {
	padding-right: 0 !important;
}

.pd-l-0 {
	padding-left: 0 !important;
}

/**
 * Bootstrap overlapping
 */
.text-info{
	color: #525252;	
}

.text-red {
	color: #E40000 !important;
}

.text-value {
	color: #50b523 !important;
	white-space: nowrap;
	font-weight: bold;
}

.text-wrap {
	white-space: pre-wrap !important;
}

.form-control:focus {
	border-color: #000;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0, 0, 0,.6);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0, 0, 0,.6);
}

.title {
	font-size: @sm-font;
	color: #525252;
}

.value {
	font-size: @md-font;
	color: #000000;
}

/**
 * Icons
 */

.icon {
	height: 24px;
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	&.icon-facebook, &.icon-twitter{
		background:url('../img/icon-socials.png')no-repeat; 
	}

	&.icon-twitter{
		background-position: -27px 0;
	}
}

.icon-lightning {
	background: url('../img/icon-lightning.png');
	height: 40px;
	width: 40px;
	position: absolute;
	bottom: 0;
	right: 15px;
}

.icon-shipping {
	background: url('../img/icon_truck.png') no-repeat center;
}

.media {
	i.icon {
		background-image: url('../img/info_icons.png');
		background-repeat: no-repeat;
		width: 25px;
		height: 26px;

		&.icon-clock{
			background-position: -2px -1px;
		}
		
		&.icon-no{
			background-position: -2px -30px;	
		}

		&.icon-five{
			background-position: -2px -60px;
		}

		&.icon-dollar{
			background-position: -2px -90px;			
		}

		&.icon-hammer{
			background-position: -2px -120px;				
		}

		&.icon-question{
			background-position: -2px -149px;
		}
	}
}

i.icon.card {
	background-image: url('../img/sprite_cards.png');
	background-repeat: no-repeat;
	width: 64px;
	height: 64px;

	&.visa {
		background-position-x: 0;
	}

	&.mastercard {
		background-position-x: -64px;
	}

	&.diners {
		background-position-x: -128px;
	}

	&.active {
		background-position-y: -64px;
	}
}

.highlight {
	transition: all 0.5s ease-in-out;
}

.highlight-over {
	color: #FF8200 !important;
	font-weight: bold;
}
