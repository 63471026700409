.alert {
	&.alert-top, &.alert-bottom {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999999999;
		border-radius: 0;
	}

	&.alert-bottom {
		top: auto !important;
		bottom: 0;
	}

	&.alert-credit-card {
		background: #ee4032;
		color: #fff;
		font-size: 18px;
		box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.75);
		transition: ease-in-out 1s all;
	}

	&.closed {
		top: -150px;
	}

	.close {
		outline: none !important;
	}
}