 .pcs-slider-wrapper-flow {	
	overflow: hidden;

	.slider-wrapper {
		overflow: hidden;
		position: relative;

		> div {
			float:left;
			position: relative;
			overflow: auto;
		}
	}

	.pcs-slide-item {
		white-space: nowrap;
	}

	.slide {
		display: inline-block;
	}

	.actions {
		text-align: center;

		.left-action, .right-action {
			border: none;
			width: 32px;
			height: 32px;
			position: absolute;
			right: 25px;
			font-size: 24px;
		    line-height: 0;
		    font-weight: 600;
		    border-radius: 10px;
		    box-shadow: white;
		    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
		    -moz-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
		    box-shadow: 7px 5px 8px -4px rgba(50, 50, 50, 0.75);
		    background: #ED1E24;
		    color: #fff;
		}

		.left-action {
			left: 25px;
		}

		.bullet {
			display: inline-block;
			width: 8px;
			height: 8px;
			background: #909090;
			border-radius: 100%;
			margin: 0px 5px 10px 5px;

			&.active {
				background: #dd0000;
			}
		}
	}
}

.ml-banner-brands {
	padding-top: 10px;
	position: relative;
	overflow: hidden;

	.slide {
		margin-right: 1.6%;

		&:last-child {
			margin-right: 0;
		}
	}

	.actions {
		position: absolute;
		top: 50%;
		margin-top: -16px;
		width: 120%;
		margin-left: -10%;
		transition: 0.5s all ease-in-out;
	}

	&:hover {
		.actions {
			width: 100%;
			margin-left: 0;
		}
	}
}

.ml-banner-top, .ml-banner-sidebar {

	position: relative;
	overflow: hidden;

	.actions {
		position: absolute;
		top: 50%;
		margin-top: -16px;
		width: 120%;
		margin-left: -10%;
		transition: 0.5s all ease-in-out;
	}

	&:hover {
		.actions {
			width: 100%;
			margin-left: 0;
		}
	}

	img {
		width: 100%;
	}
}

.ml-banner-sidebar {
	.actions {
		width: 170%;
		margin-left: -30%;
	}
}