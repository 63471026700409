.auction {
	font-style: italic;
	font-size: 12px;
	padding: 5px;
	transition: 1s all ease;

	.content {
		border: 1px solid #fff;
		overflow: auto;
	}

	h4 {
		margin: 5px 0;
		font-size: 13px;
		color: #000;
		min-height: 45px;
	}

	.max-payment-date {
		font-size: 9px;
		background: #F0F0F0;
		padding: 5px 15px;
		margin: 10px -15px;
		text-align: center;
		cursor: default;

		.value {
			font-size: 10px;
			color: #D00;
			font-weight: bold;
		}
	}

	.price, .selected-size {
		font-size: 9px;
		text-align: center;
		margin: 5px 0;
		line-height: 13px;
		padding: 0;

		.value {
			display: block;
			font-size: 16px;
			font-weight: bold;
		}
	}

	.select {
		text-align: center;
		background-color: #222;
		color: #FFF;
		padding: 6px;
		transition: 0.5s all ease;
		font-weight: bold;
		cursor: pointer;
		text-transform: uppercase;
		input {
			display: none;
			visibility: hidden;
		}
	}

	&.table {
		.address {
			white-space: pre-wrap;
		}

		.select {
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			-ms-border-radius: 3px;
			-o-border-radius: 3px;
			border-radius: 3px;
		}
	}

	&.selected, .selected {
		.content {
			border-color: #222;
		}

		.select {
			background-color: #d00;
		}
	}
}

.shipping-method {
	cursor: pointer;
	padding-top: 15px;
	border-radius: 3px;
	margin-bottom: 15px;
	border: 1px solid rgba(0, 0, 0, 0);

	.shipping-name {
		margin-bottom: 10px;
	}

	.shipping-estimate {
		p {
			font-size: @md-font - 1;
		}
	}

	.shipping-price {
		top: -30px;
		border-radius: 5px;
		padding-top: 10px;
		padding-bottom: 10px;

		.text-value {
			font-size: @xlg-font + 2;
		}
	}

	.shipping-selector {
		display: none;
		visibility: hidden;
	}

	&.selected {
		border: 1px solid #222;

		.shipping-price {
			background: #F0F0F0;
		}
	}
}

.order-info {
	.text-value {
		font-size: 3em;
		line-height: 64px;
	}
}

.order-payment {
	.order-payment-info {
		padding-top: 13px;
	}
	.type {
		display: block;
	}
}

.subtotal-items {
	h3 {
		margin: 0;
	}
}

.select-checked {
    background: url('../img/icon_checked.png') no-repeat 0px !important;
    padding: 12px;
}

.select-unchecked {
    background: url('../img/icon_unchecked.png') no-repeat 0px !important;
    padding: 12px;
}

.details {
    background: url('../img/icon_detail.png') no-repeat 5px !important;
    padding: 15px;
}

.formas-pagamento label > input + span {
    background: url('../img/icon_unchecked.png') no-repeat 10px !important;
    padding: 20px;
}

.formas-pagamento label > input {
    &[type="radio"] {
        &:checked + span {
            background: url('../img/icon_checked.png') no-repeat 10px !important;
            padding: 20px;
        }
        margin-left: 14px;
        opacity: 0;
    }
}

.mobile, .tablet {
	.radio.disabled {
		label {
			background: #e1e1e1 !important;
			color: #c0c0c0 !important;
		}
		p {
			position: absolute;
			top: 0;
			color: #dd0000;
			background: rgba(255,255,255, 0.7);
			left: 0;
			right: 0;
			bottom: 0;
			padding: 70px 0 0;
			margin: 0;
		}
	}
}

.desktop {
	.radio.disabled {
		p {
			position: absolute;
			top: 0;
			color: #dd0000;
			background: rgba(255, 255, 255, 0.85);
			left: 0;
			right: 0;
			bottom: 0;
			padding: 20px 10px 0;
			margin: 0;
			font-weight: bold;
		}
	}
}