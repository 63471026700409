/* lists */
.list-square-items {
	li {
		margin-bottom: 10px;
		a {
			color: #000000;
			border: 1px solid #000000;
			display: block;
			padding: 4px;
			height: 25px;
			line-height: 15px;
			text-align: center;
			font-weight: bold;
			min-width: 25px;
			border-radius: 0px;

			&:hover {
				border-color: #909090;
				color: #909090;
			}
		}

		&.active a {
			border-color: #e40000;
			color: #e40000;
		}
	}
}
@media (max-width: 767px) { /* Aplica estilos para telas menores que 768px (dispositivos móveis) */
  .repay {
    width: 100% !important; /* Garante que o elemento ocupe a largura total da tela */
    text-align: center !important; /* Centraliza o conteúdo horizontalmente */
    float: none !important; /* Remove o comportamento de float */
    padding-right: 0 !important; /* Remove o padding direito */
  }

  .repay > div {
    display: flex !important; /* Torna o contêiner flexível */
    flex-direction: column !important; /* Organiza os elementos em coluna */
    align-items: center !important; /* Centraliza os itens horizontalmente */
  }
}