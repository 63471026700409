table{

	thead{
		
		background-color: #f5f5f5;
		border: 1px solid #ddd;

		th{
			border-bottom: none !important;
			font-weight: normal;
			font-size: 16px;
			color: #303030 !important;
		}
	}

	tbody{
		border: 1px solid #d1d1d1;
	}

}

table.auction{
	
	margin: 10px 0;

	thead{
		
		th:nth-last-child(2){
			text-align: center;
		}

	}

}

#checkout-payment{
	table{
		thead{
			th{
				font-style: italic;
				font-style: 16px;
			}
		}
	}	
}

.cart-items .table > tbody > tr.product-detail > td:last-child, .cart-items .table > thead > tr > th:last-child{
	text-align: center;
}
	

@media screen and (max-width: 767px){
	.orders-container .table > tbody > tr > td,	.cart-items .table > tbody > tr > td{
		white-space: normal;
	}

}