/* footer */

footer {
	width: 100%;
	position: absolute;
	bottom: 0;

	.footer-share{
		color: #fff;
		margin: 0 -15px;
		border-radius: 0;
		border: 0;
		padding: 10px 15px;
		min-height: inherit;
	}

	.footer-share.navbar-inverse .navbar-text {
		color: #ffffff;
		font-family: 'Oswald', 'Lato';
		font-size: @md-font;
		text-transform: uppercase;
		margin: 0;

		a {
			vertical-align: text-top;
		}
	}

	.footer-info, .footer-utils {
		background: #505050;
		color: #E8E8E8;
		font-size: @sm-font;
		padding: 10px 0;

		address, p {
			font-size: @sm-font;
			margin-bottom: 0;
		}

		p {
			font-weight: 600;
		}
	}

}

.desktop footer {
	h4 {
		font-style: normal;
		color: #fff;
		text-transform: uppercase;
		font-size: @md-font;
		font-family: "Oswald", Lato;
	}

	a {
		font-size: @sm-font;
		color: #fff;

		&:hover {
			color: #e9e9e9;
		}
	}

	.footer-info {
		background: #e9e9e9;
		color: #000;
		border-top: 2px solid #e6e6e6;
		h4 {
			color: #000;
		}

		a {
			margin-right: 15px;
		}
	}
	.footer-share {
		p {
			line-height: 30px;
			a {
				vertical-align: inherit !important;
			}
		}
	}
}