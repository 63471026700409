.web-notification {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.3);
	z-index: 9999;
	overflow: auto;

	.web-notification-wrapper {
		background-color: #FFF;
		border-radius: 6px;
		width: 50%;
		margin: 3% auto;
		z-index: 9999999;
		box-shadow: 1px 2px 2px #555;
		padding: 1% 2%;

		p {
  			font-style: italic;
		}

		img {
			margin-top: 3px;
			margin-bottom: 15px;
			border: 1px solid #CCC;

			&.pull-right {
				margin-left: 15px;
			}
			&.pull-left {
				margin-right: 15px;
			}
			&.notification-dialog-preview {
				width: 270px;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.web-notification {
		.web-notification-wrapper {
			width: 95%;
			padding-left: 5%;
			padding-right: 5%;

			.media {
				.media-left, .media-right {
					width: 100%;
					display: block;
					text-align: center;
				}
			}
		}
	}
}