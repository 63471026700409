.submenu-mobile {
	background-color: #202020;
	padding-top: 10px;
	padding-bottom: 10px;

	.btn-options {
		background-color: transparent;
		border: none;
		color: #fff;
		padding: 0;
		margin: 0;

		&.btn-search {
			margin-left: 35px;
		}
	}
}

.tablet {
	.submenu-mobile {
		.btn-options {
			line-height: 30px;
		}
	}
}

.mobile, .tablet {
	.cart-holder {
		margin: -10px -15px;

		> li {
			position: inherit;

			> a {
				color: #fff;
			}

			> ul {
				position: absolute;
				left: 0px;
				z-index: 1;
				width: 100%;
				box-shadow: 0px 0px 5px #909090;
			}
		}
	}
}

/**
 * Header
 */
nav.mobile-menu {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	border: none;
	padding: 40px 0 0 0;
	display: none;
	visibility: hidden;

	.navbar-header {
		display: none;
	}

	.navbar-collapse {
		padding-top: 2px;
		border-top: none;
		-webkit-webkit-box-shadow: none;
		-moz-webkit-box-shadow: none;
		-ms-webkit-box-shadow: none;
		-o-webkit-box-shadow: none;
		box-shadow: none;
		overflow-y: auto;
		display: block;
		visibility: visible;
	}

	.navbar-nav {
		margin: 0 -15px;
	}

	// .cart-holder {
	// 	display: none;
	// 	visibility: hidden;
	// }

	&.show {
		display: block;
		visibility: visible;
	}
}

header {
	#useful-links {
		padding-top: 10px;
	}

	.user-logged {
		.dropdown-menu {
			right: inherit;
  			top: auto;
  			z-index: 1030;
		}

		.glyphicon-star, .glyphicon-off {
			color: #FCC900;
		}

		.glyphicon-off {
			color: #dd0000;
		}
	}

	.logged-info {
		.dropdown-menu {
			z-index: 1030;
			width: 100%;
			top: 80%;
			background-color: #4EB623;
			border: none;

			> li {
				background: #FFF;
				margin: 0 3px;
				border-bottom: 1px solid #DDD;

				&.first, &.first a {
					border-radius: 5px 5px 0px 0px;
				}

				&.last, &.last a {
					border-radius: 0px 0px 5px 5px;
				}

				> a {
					padding: 7px 20px;
				}
			}

			.badge {
				min-width: 28px;
				background-color: #4EB623;
				line-height: 21px;
				-o-border-radius: 100%;
				-ms-border-radius: 100%;
				-moz-border-radius: 100%;
				-webkit-border-radius: 100%;
				border-radius: 100%;
			}
		}
	}

	&.icons {
		// border-bottom: 1px solid #ccc;
		border-bottom: 1px solid #2F3192;
		background-color: #2F3192;

		#logo {
			margin: 5px 0;
		}

		#useful-links {
			padding-top: 0px;

			> ul {
				float: right;
				margin-top: 21px;

				.dropdown-menu {
					left: -165px;
				}

				.user-dropdown .dropdown-menu {
					left: -90px;
				}

				> li > a, 
				> li > .dropdown > a {
					width: 24px;
					overflow: hidden;
					padding: 0;
					// color: #141683;
					color: #fff;
					display: inline-block;

					.glyphicon {
						font-size: 24px;
					}
				}
			}
		}

		.search-bar {
			background: #f0f0f0;
			padding: 10px 15px;
			transition: 1s opacity ease;
			opacity: 1;
		}


		.hidden-xs.search-bar {
			opacity: 0;
		}
	}
}