/*
 * MODAL
 */
.modal-wrapper {
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1030;
	position : fixed;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #ffffff;

	.offer-bid-history{
		padding-bottom: 50px;
	}

	.modal-close {
		position: absolute;
		width: 100%;
		background-color: #ed1e24;
		a {
			display: block;
			padding: 10px;
			text-align: right;
			color: #fff;
			font-size: 16px;
		}
	}

	.modal-content {
		height: 100%;
		overflow: auto;
		width: 100%;
		box-sizing: border-box;
		overflow-x: hidden;

		background-color: transparent;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: none;
		border-radius: none;
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;

		padding-top: 40px;

		.messages {
			margin-top: -5px;
		}
	}
}

/**
 * Helpers
 */
.content-lock {
	overflow: hidden;
}

.desktop {
	.modal-wrapper {
		width: 40%;
		box-shadow: -3px 0 5px #909090;
	}

	&.content-lock {
		overflow: inherit;
	}
}

