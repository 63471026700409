/**
 * Notifications
 */
.notification-bar {
	position: fixed;
	top: 0;
	width: 100%;
	height: 40px;
	background: #222222;
	z-index: 1031;
	border-bottom: 1px solid #101010;
	-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
	box-shadow: 0px 1px 0 rgba(255,255,255,.1);
	// transition: all 0.5s ease;

	&.notification-success {
		background-color: #4eb623;
		border-color: #277d03;
		transition: all 0.5s ease;
	}

	&.notification-error {
		background-color: #ed1e24;
		border-color: #b90d12;
		transition: all 0.5s ease;
	}

	.notification-actions {
		width: 36px;
		float: right;
		box-sizing: border-box;
		text-align: center;

		.glyphicon {
			font-size: @xlg-font;
			color: #fff;
			padding: 10px;
			cursor: pointer;
		}
	}

	> .notification-title {
		padding-left: 15px;
	}

	.notification-title {
		color: #fff;
		font-size: @xlg-font;
		font-family: oswald;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		box-sizing: border-box;
		line-height: 40px;
		float: left;
	}

	.notification-loader {
		width: 36px;
		height: 100%;
		float: right;
		box-sizing: border-box;
		line-height: 40px;
		text-align: center;
		background: url('../img/loader.svg') no-repeat center;
	}

	.notification-holder {
		clear: both;
		overflow: auto;

		.notification {
			background: #555;
			overflow: auto;
			padding: 5px 15px;
			position: relative;

			.notification-title {
				clear: both;
				float: none;
				font-size: 16px;
				font-weight: bold;
				line-height: inherit;
			}

			.notification-content {
				color: #fff;
			}

			&:not(:last-child) {
				margin-bottom: 2px;
			}

			.notification-action {
				position: absolute;
				right: 5px;
				top: 50%;
				margin-top: -7px;
			}
		}
	}
}

.desktop {
	> .container-fluid {
		// transition: 0.5s all ease-in-out;
		// margin-top: -39px;
	}

	.notification-bar {
		// position: relative;
	}
}