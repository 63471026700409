/**
* ACCORDION
*/

.megaleilao-accordion {
	.megaleilao-accordion-header {
		margin: 0 -15px;
		background: #ffffff;
		padding: 10px 15px;
		font-style: normal;
		border-bottom: 1px solid #ccc;
		.badge {
			background-color: #ed1e24;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.megaleilao-accordion-content {
		padding: 15px 0;
		hr, h2 {
			display: none;
			visibility: hidden;
		}
	}
}

table{
	.panel-group{
		.panel-heading{
			padding: 0;
		}
		h4{
			a{
				display: block;
				padding: 10px 15px;
			}
		}
	}
}

.accordion {
	.panel {
		border: 0 !important;
		
		.panel-heading {
			padding: 0;

			h3 {
				padding: 10px 15px;
			}

			h4 {
				a {
					font-style: italic;
					padding: 10px 15px;
					display: block;

					&:active, &:focus {
						color: #303030;
					}
					
					.glyphicon {
						font-size: 11px;
						margin-right: 5px;
					}
				}
			}
		}

		.panel-collapse {
			border-top: 0;

			> table {
				margin-top: 5px;
			}
		}
	}
}

.bids-container {

	.img-circle {
		display: inline-block;
		overflow: hidden;
		padding: 5px;
		background-color: #FFF;
		vertical-align: middle;
		margin-right: 15px;
		border: 2px solid #EEE;

		img {
			max-width: 32px;
		}
	}

	// .panel {
	// 	border: 0 !important;
		
	// 	.panel-heading {
	// 		padding: 0;
	// 		h4 {
	// 			a {
	// 				font-style: italic;
	// 				padding: 10px 15px;
	// 				display: block;
	// 			}
	// 		}
	// 	}
	// }

	.panel-collapse {
		border: 1px solid #ddd;
		border-top: 0;

		.panel-body {
			padding: 0;

			.offer-name {
				a {
					font-style: italic;
					display: block;
					padding: 5px 15px;

					img {
						max-width: 96px;
					}
				}
			}

			.pull-right {
				table {
					margin-bottom: 0;
					margin-top: -1px;
					margin-left: 1px;
					margin-bottom: -1px;

					th {
						font-style: italic;
					}

					td, th {
						height: 55px;
						vertical-align: middle;
					}
				}

				@media only screen and (max-width: 768px){
					float: none !important;
				}
			}
		}
	}
}

#checkout-payment {
	.panel-group {
		.panel-body {
			min-height: 100px
		}

		.panel-title {
			font-style: italic;
			font-weight: 600;
		}
	}
}
