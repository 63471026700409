.user-confirm {
	#content {
		text-align: center;
		padding-bottom: 5%;
	}

	h2 {
		text-transform: uppercase;
	}

	h2, p {
		margin: 10% 0;
	}
}

.profile-container {
	h1 {
		margin-top: 0px;
	}

	h2 {
		margin-top: 25px;
	}
}
